import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import bpoint from "../../assets/images/home/bulletpoint.svg";
import Readmore from "../../assets/images/home/chevron-right.svg";
import Rightimage from "../../assets/images/home/Rectangle 532.png";
import Leftimage2 from "../../assets/images/home/leftimage2.png";
import Rightsubimage from "../../assets/images/home/prjctpeace.png";
import Rightimage2 from "../../assets/images/home/Rectangle 544.png";
import leftimage from "../../assets/images/home/Rectangle 546.png";

import "./Home.css";
import Screen from "./Screen";

const Home = () => {
  const Navigate = useNavigate();
  const handleButtonClick = () => {
    Navigate("/about");
  };

  const handleButtonClick1 = () => {
    Navigate("/project");
  };

  return (
    <div>
      <Screen />
      <br />
      <br />
      <br />

      <div className="container">
        <div
          style={{ gap: "12px" }}
          className="d-flex flex-column-reverse flex-sm-row"
        >
          <div className="col-sm-6 ">
            <p
              style={{
                fontFamily: "poppins",
                color: "#85C71F",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              ABOUT US
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "60px",
                top: "982px",
                left: "197px",
              }}
            >
              Ecovision Capital Pte Ltd
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              Our unwavering commitment to this noble vision is encapsulated in
              our five foundational pillars: EcoCarbon, EconTech, EcoEnergy,
              EcoInnovation, and EcoWellness.
            </p>

            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              At EcoVision, we meticulously select distinguished experts in each
              pillar and cultivate strategic partnerships to develop distinctive
              initiatives that drive tangible impact. Presently, our focus
              shines on the EcoCarbon pillar, where we have taken a momentous
              stride by fully backing a visionary carbon developer to spearhead
              a large-scale, premium carbon credit program. Aligned with our
              ambitious objectives, we aim to secure USD 100 million to amplify
              our collaboration's impact across all pillars.
            </p>

            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              This substantial funding will bolster the success of our solutions
              as we collaborate with domain experts. Our ultimate aim is to
              catalyze a positive ripple effect, contributing to a sustainable,
              equitable, and flourishing world.
            </p>

            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              Embark on this transformative journey with us as we endeavor to
              raise funds and contribute to a legacy that transcends
              generations. At EcoVision Capital, innovation converges with
              responsibility, and every investment represents a significant
              stride towards a brighter, more sustainable future.
            </p>
            <br />

            <Button onClick={handleButtonClick} style={{ cursor: "pointer" }}>
              Read more
              <ReadMoreButton>
                <img src={Readmore} alt="Image" />
              </ReadMoreButton>
            </Button>
          </div>

          <div className="col-sm-6 d-flex justify-content-center ">
            <img
              className="object-fit-cover"
              src={Rightimage}
              alt="image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <br />

        <div
          style={{ gap: "25px" }}
          className="d-flex flex-column flex-sm-row g-3 "
        >
          <div className="col-sm-6 d-flex justify-content-center  ">
            <img
              className="object-fit-cover image-height"
              src={leftimage}
              alt="image"
              style={{}}
            />
          </div>
          <div className="col-sm-6 ">
            <p
              style={{
                fontFamily: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "60px",
              }}
            >
              Our Process
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              The project aims to partner with the CADTs for forest
              conservation, reforestation, rehabilitation, and agroforestry
              activities. The goal is to reduce greenhouse gas emissions.
            </p>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div
                class="accordion-item "
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                    style={{
                      border: "1px solid #ACACAC",
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                    }}
                  >
                    Financial Support
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      color: "#666666",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    class="accordion-body"
                  >
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Dedicated to development support with developer's
                          commitment to ensure funding suffices for initial
                          carbon credit issuance
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse2"
                    aria-expanded="false"
                    aria-controls="flush-collapse2"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Land Use And Activities
                  </button>
                </h2>
                <div
                  id="flush-collapse2"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          {" "}
                          Forest areas for reducing deforestation and
                          rehabilitating degraded forests
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Non-forest areas for reforestation and agroforestry
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse7"
                    aria-expanded="false"
                    aria-controls="flush-collapse7"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Participatory Process (FPIC)
                  </button>
                </h2>
                <div
                  id="flush-collapse7"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Participatory process to engage with indigenous people
                          to work with them on how they want to develop their
                          land (Social Impact Land Use Planning, SILUP)
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          CADTs decide land use for the project through
                          Participatory Land Use Planning (PLUP)
                        </span>
                      </div>

                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Official project boundaries determined by CADTs
                          through PLUP
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse6"
                    aria-expanded="false"
                    aria-controls="flush-collapse6"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Land Access
                  </button>
                </h2>
                <div
                  id="flush-collapse6"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          {" "}
                          CADT's access to their land will never be restricted
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5"
                    aria-expanded="false"
                    aria-controls="flush-collapse5"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Performance Evaluation
                  </button>
                </h2>
                <div
                  id="flush-collapse5"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Annual project evaluation to measure project's
                          performance
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Measurement of avoided deforestation and replanted
                          forest hectares
                        </span>
                      </div>

                      {/* <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Issuance of carbon credits by DENR based on carbon
                          impact
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse4"
                    aria-expanded="false"
                    aria-controls="flush-collapse4"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Benefit Sharing
                  </button>
                </h2>
                <div
                  id="flush-collapse4"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Economic and social benefits explained through the
                          Benefit Sharing Mechanism
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse3"
                    aria-expanded="false"
                    aria-controls="flush-collapse3"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Consent
                  </button>
                </h2>
                <div
                  id="flush-collapse3"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          CADT gives consent to participate in the project based
                          on the understanding of relevant information and prior
                          to the commencement of activities
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div
        style={{
          backgroundImage: "url(/image/IMG_5892.jpg)", // Relative to the public folder
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "50px 20px 80px 30px",
          // width: '100%',
          // height: '400px
        }}
      >
        <div
          className="row mx-auto container"
          style={{ maxWidth: "1620px", gap: " 30px 0px" }}
        >
          <div
            className=" col-sm-6 padding1"
            style={{ padding: "130px 70px 0 70px" }}
          >
            <p
              style={{
                fontFamily: "poppins",
                color: "#FFFFFF",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              Our Project
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "60px",
              }}
            >
              Project Peace
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Our bold initiative aimed at generating carbon credits by
              mitigating and removing greenhouse gas emissions. Through
              strategic efforts to prevent planned and unplanned deforestation,
              engage in afforestation and reforestation, and safeguard peatland,
              we are not just reducing emissions - We're cultivating a
              sustainable and harmonious future.
              <br />
              <br />
              <p>
                <span
                  style={{
                    fontFamily: "poppins",
                    color: "#FFFFFF",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Aim:
                </span>
                <span style={{ marginLeft: "4px" }}>
                  Partner with CADTs for forest conservation, reforestation,
                  rehabilitation, and agroforestry activities. The goal is to
                  reduce greenhouse gas emissions.
                </span>
              </p>
            </p>
            <br />
            <Button
              onClick={handleButtonClick1}
              className="test"
              style={{ cursor: "pointer" }}
            >
              Read more
            </Button>
          </div>

          <div className="col-sm-6 d-flex justify-content-center position-relative">
            <img
              className="object-fit-cover padding2"
              src={Rightimage2}
              alt="image"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                padding: "70px 70px 100px 50px",
              }}
            />
            <div
              className="position-absolute" 
              style={{
                width: "50%",
                height: "44%",
                top: "53%", // Adjust this to position the subimage as needed
                left: "-3%", // Adjust this to position the subimage as needed
                padding: "2px", // Adjust this to change the border size
                backgroundColor: "#FFFFFF", // Set the border color
                borderRadius: "20px",
                border: "2px solid white",
              }}
            >
              <img
                className="object-fit-cover"
                src={Rightsubimage}
                alt="image"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "18px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

      <div className="container">
        <div
          style={{ gap: "25px" }}
          className="d-flex flex-column flex-sm-row "
        >
          <div className="col-sm-6 d-flex justify-content-center  ">
            <img
              className="object-fit-cover image-height1  "
              src={Leftimage2}
              alt="image"
            />
          </div>

          <div className="col-sm-6 ">
            <div class="accordion accordion-flush" id="accordionFlushExample1">
              <div
                class="accordion-item "
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                    style={{
                      border: "1px solid #ACACAC",
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                    }}
                  >
                    Carbon Vertical Development
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      color: "#666666",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    class="accordion-body"
                  >
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">Peatland restoration</span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Restoration and enrichment of degraded forests
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse2"
                    aria-expanded="false"
                    aria-controls="flush-collapse2"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Land Use Management & Protection Activitie
                  </button>
                </h2>
                <div
                  id="flush-collapse2"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Land use planning enforcement
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Forest protection, poaching wardens, and patrols
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse3"
                    aria-expanded="false"
                    aria-controls="flush-collapse3"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Climate-Smart Economic Vertical Development
                  </button>
                </h2>
                <div
                  id="flush-collapse3"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          {" "}
                          Community-based forest management
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">Agroforestry</span>
                      </div>

                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">Agricultural extension</span>
                      </div>

                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Other non-timber forest commodities
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse4"
                    aria-expanded="false"
                    aria-controls="flush-collapse4"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Rural Education
                  </button>
                </h2>
                <div
                  id="flush-collapse4"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Construction of education facilities
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Development of indigenously conscious curriculum
                          blending national standards and relevant indigenous
                          history & culture
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5"
                    aria-expanded="false"
                    aria-controls="flush-collapse5"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Rural Clinics & Medical
                  </button>
                </h2>
                <div
                  id="flush-collapse5"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Construction of medical facilities
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Support for student and school health programs
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Broader public health support
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Home;

const Button = styled.div`
  display: flex;
  width: 184px;
  height: 57px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 10px;
  border: 1px solid #000;
  border-radius: 30px;
  &.test {
    border: 1px solid #ffffff;
    color: #ffffff;
  }
`;
const ReadMoreButton = styled.div``;
