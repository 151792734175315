import React from "react";
import bpoint from "../../assets/images/home/bulletpoint.svg";
import Leftimage2 from "../../assets/images/home/leftimage2.png";
import Screen2 from "./Screen2";
import "./Solutions.css";

const Solutions = () => {
  return (
    <div>
      <Screen2 />
      <br />
      <br />
      <br />

      <div className="container">
        <div
          style={{ gap: "25px" }}
          className="d-flex flex-column flex-sm-row "
        >
          <div className="col-sm-6 d-flex justify-content-center  ">
            <img
              className="object-fit-cover image-height2"
              src={Leftimage2}
              alt="image"
              style={{}}
            />
          </div>

          <div className="col-sm-6 ">
            <div class="accordion accordion-flush" id="accordionFlushExample1">
              <div
                class="accordion-item "
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                    style={{
                      border: "1px solid #ACACAC",
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                    }}
                  >
                    Carbon Vertical Development
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      color: "#666666",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    class="accordion-body"
                  >
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">Peatland restoration</span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Restoration and enrichment of degraded forests
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse2"
                    aria-expanded="false"
                    aria-controls="flush-collapse2"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Land Use Management & Protection Activitie
                  </button>
                </h2>
                <div
                  id="flush-collapse2"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Land use planning enforcement
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Forest protection, poaching wardens, and patrols
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse3"
                    aria-expanded="false"
                    aria-controls="flush-collapse3"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Climate-Smart Economic Vertical Development
                  </button>
                </h2>
                <div
                  id="flush-collapse3"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          {" "}
                          Community-based forest management
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">Agroforestry</span>
                      </div>

                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">Agricultural extension</span>
                      </div>

                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Other non-timber forest commodities
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse4"
                    aria-expanded="false"
                    aria-controls="flush-collapse4"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Rural Education
                  </button>
                </h2>
                <div
                  id="flush-collapse4"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Construction of education facilities
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Development of indigenously conscious curriculum
                          blending national standards and relevant indigenous
                          history & culture
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  borderRadius: "10px",
                  border: "1px solid #ACACAC",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5"
                    aria-expanded="false"
                    aria-controls="flush-collapse5"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Rural Clinics & Medical
                  </button>
                </h2>
                <div
                  id="flush-collapse5"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample1"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Construction of medical facilities
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Support for student and school health programs
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Broader public health support
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div
        style={{
          backgroundImage: "url(/image/solutionF.png)", // Relative to the public folder
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "50px 30px 80px 30px",
          width: "100%",
          marginBottom: "-48px",
        }}
      >
        <div class="d-flex justify-content-evenly flex-wrap">
          <div className="d-flex flex-column align-items-start">
            <h3
              style={{
                marginLeft: "130px",
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "37px",
                marginBottom: "-20px",
              }}
            >
              Scale
            </h3>

            <p
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "12px",
                width: "300px",
                lineHeight: "18px",
                padding: "10px",
                margin: "20px",
                boxSizing: "border-box",
                textAlign: "center",
              }}
            >
              Roll out the program with Indigenous CADTs over 25 years
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <h3
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "37px",
                marginBottom: "-20px",
              }}
            >
              Designed For The People
            </h3>

            <p
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "12px",
                width: "300px",
                lineHeight: "18px",
                padding: "10px",
                margin: "20px",
                boxSizing: "border-box",
                textAlign: "center",
                // marginBlock: "0",
              }}
            >
              Program is entirely centered upon a community-based approach to
              implementation
            </p>
          </div>

          <div className="d-flex flex-column align-items-end">
            <h3
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "37px",
                marginBottom: "-20px",
              }}
            >
              Two Interrelated Products
            </h3>

            <p
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "12px",
                width: "300px",
                lineHeight: "18px",
                padding: "10px",
                margin: "20px",
                boxSizing: "border-box",
                textAlign: "center",
                // marginBlock: "0",
              }}
            >
              Carbon credit production

            </p>
            <p
              style={{
                marginTop:"-25px",
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "12px",
                width: "300px",
                lineHeight: "18px",
                
                boxSizing: "border-box",
                textAlign: "center",
                // marginBlock: "0",
              }}
            >
              Community-based agroforestry and non-timber forest products
              
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
