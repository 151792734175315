import React from "react";
import slide1 from "../../assets/images/about/abt.png";

const Screen1 = () => {
  return (
    <div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src={slide1} class="d-block w-100" alt="image" />
          <div class="h-100 carousel-caption d-md-block">
            <div className="h-100 d-flex justify-content-center flex-column align-items-center">
              <h2
                className="mt-4 display-1 "
                style={{
                  fontFamily: "poppins",
                  color: "#FFFFFF",
                  fontWeight: "700",
                  // lineHeight: "105px",
                }}
              >
                About Us
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen1;
