import React from "react";
import logo from "../assets/images/H&F/logo-og.svg";
import FB from "../assets/images/H&F/Facebook.svg";
import insta from "../assets/images/H&F/insta.svg";
import twitter from "../assets/images/H&F/Twitter.svg";

const Footer = () => {
  return (
    <footer
      style={{ backgroundColor: "#282C21" }}
      className="text-center text-lg-start text-muted"
    >
      <section>
        <div className="container text-center text-md-start mt-5">
          <div
            className="d-flex flex-md-row flex-column gap-4"
            style={{
              padding: "50px 0 50px 0",
            }}
          >
            <div className="d-flex flex-column align-items-center align-items-sm-start">
              <img src={logo} width="331px" height="82px" alt="Logo" />

              <p
                style={{
                  fontFamily: "poppins",
                  color: "#FFFFFF",
                  fontWeight: "400",
                  fontSize: "14px",
                  width: "370px",
                  lineHeight: "21px",
                  padding: "10px 0 10px 0",
                  margin: "20px 0 20px 0",
                  boxSizing: "border-box",
                }}
              >
                Introducing a sustainable socio-economic model to the region
                based on preservation, not destruction
              </p>
            </div>
            <div className="d-flex flex-column flex-sm-row w-100 justify-content-between gap-5 align-items-center align-items-sm-baseline">
              <div className="d-flex w-100 justify-content-between justify-content-sm-evenly gap-5 align-items-baseline">
                <div
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "poppins",
                    fontSize: "15px",
                    lineHeight: "22.5px",
                    fontWeight: "400",
                  }}
                  className="d-flex flex-column gap-1"
                >
                  <a href="/" className="text-reset text-decoration-none">
                    Home
                  </a>
                  <a href="/about" className="text-reset text-decoration-none">
                    About Us
                  </a>
                  <a href="/project" className="text-reset text-decoration-none">
                    Projects
                  </a>
                </div>

                <div
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "poppins",
                    fontSize: "15px",
                    lineHeight: "22.5px",
                    fontWeight: "400",
                  }}
                  className="d-flex flex-column gap-1"
                >
                  <a href="/solution" className="text-reset text-decoration-none">
                    Solutions
                  </a>
                  <a href="/team" className="text-reset text-decoration-none">
                    Team
                  </a>
                  <a href="/contact" className="text-reset text-decoration-none">
                    Contact Us
                  </a>
                </div>
              </div>
              <div
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "600",
                  marginTop: "20px",
                }}
                className="d-flex flex-column align-items-left"
              >
                <p className="text-reset">Follow Us</p>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-start flex-row gap-3">
                  <a
                    href="https://www.instagram.com/ecovisioncapital/?hl=en"
                    className="text-reset text-decoration-none d-flex justify-content-center align-items-center"
                    style={{
                      border: "2.181px solid #85C71F",
                      borderRadius: "50px",
                      width: "43px",
                      height: "43px",
                    }}
                  >
                    <img className="object-fit-cover" src={insta} alt="Instagram" />
                  </a>

                  <a
                    href="https://www.facebook.com/profile.php?id=61560301516230"
                    className="text-reset text-decoration-none"
                  >
                    <img className="object-fit-cover" src={FB} alt="Facebook" />
                  </a>

                  <a
                    href="https://x.com/eco_capital"
                    className="text-reset text-decoration-none"
                  >
                    <img className="object-fit-cover" src={twitter} alt="Twitter" />
                  </a>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />

                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "400",
                    boxSizing: "border-box",
                    width: "200px",
                    textAlign: "right",
                  }}
                >
                  Designed & Developed by{" "}
                  <a 
                    href="https://www.zartek.in"
                    className="text-decoration-none"style={{color:'#85C71F'}}
                  >
                    Zartek
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
  