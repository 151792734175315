import React from "react";
import bpoint from "../../assets/images/home/bulletpoint.svg";
import prjctimg from "../../assets/images/project/Overview1.png";
import i2 from "../../assets/images/project/bird.svg";
import i3 from "../../assets/images/project/coin.svg";
import prjctlast from "../../assets/images/project/prjct last.png";
import i1 from "../../assets/images/project/tree.svg";
import "./Project.css";
import Screen3 from "./Screen3";

const Projects = () => {
  return (
    <div>
      <Screen3 />
      <br />
      <br />
      <br />

      <div className="d-flex justify-content-center flex-wrap ">
        <div className="d-flex flex-column align-items-center gap-4">
          <h1
            style={{
              font: "poppins",
              color: "#000000",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              width: "100%",
            }}
          >
            Project Objective
          </h1>
          <div className="d-flex flex-row gap-4 flex-wrap flex-md-nowrap justify-content-center">
            <span
              class="p-5"
              style={{
                height: "330px",
                width: "380px",
                textAlign: "center",
                border: "1px solid #85C71F",
                borderColor: "#85C71F",
              }}
            >
              <h3
                style={{
                  font: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "33px",
                  textAlign: "left",
                }}
              >
                Strategic Carbon Portfolio
              </h3>
              <p
                style={{
                  font: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                We have identified a few promising carbon credit projects,
                notably, our flagship initiative, Project Peace. Project Peace
                is currently under development and is fully funded by a company
                under our group: EcoCarbon.
              </p>
            </span>
            <span
              style={{
                height: "330px",
                width: "380px",
                textAlign: "center",
                border: "1px solid #85C71F",
                borderColor: "#85C71F",
                padding: "35px",
              }}
            >
              <h3
                style={{
                  font: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "33px",
                  textAlign: "left",
                }}
              >
                Project Peace’s Core Objective
              </h3>
              <p
                style={{
                  font: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                This Project is our catalyst for generating carbon credits.
                Through precise measures such as deforestation prevention,
                targeted afforestation and reforestation, we are mitigating
                greenhouse gas emissions and preserving vital peatlands.
              </p>
            </span>
            <span
              class=" p-5"
              style={{
                height: "320px",
                width: "380px",
                textAlign: "center",
                border: "1px solid #85C71F",
                borderColor: "#85C71F",
              }}
            >
              <h3
                style={{
                  font: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "33px",
                  textAlign: "left",
                }}
              >
                Cultural Guardianship
              </h3>
              <p
                style={{
                  font: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                In tandem with environmental goals, this Project safeguards the
                land, culture, and heritage of recognized tribes, fortifying a
                harmonious balance between nature and tradition.
              </p>
            </span>
          </div>
          <div className="d-flex flex-row gap-4 flex-wrap flex-md-nowrap justify-content-center">
            <span
              class=" p-5"
              style={{
                height: "320px",
                width: "380px",
                textAlign: "center",
                border: "1px solid #85C71F",
                borderColor: "#85C71F",
              }}
            >
              <h3
                style={{
                  font: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "33px",
                  textAlign: "left",
                }}
              >
                Economic Empowerment Drive
              </h3>
              <p
                style={{
                  font: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                Beyond environmental impact, this Project fuels economic
                transformation. With a focus on over 250,000 indigenous
                residents, our initiative sparks opportunities, fostering
                economic opportunities in the region.
              </p>
            </span>
            <span
              class=" p-5"
              style={{
                height: "320px",
                width: "380px",
                textAlign: "center",
                border: "1px solid #85C71F",
                borderColor: "#85C71F",
              }}
            >
              <h3
                style={{
                  font: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "33px",
                  textAlign: "left",
                }}
              >
                Sustainable Revenue Horizon
              </h3>
              <p
                style={{
                  font: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                This Project is poised for a 25-year horizon, projecting the
                aggregation of carbon credit revenue stream.
              </p>
            </span>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

      <div className="d-flex flex-column flex-sm-row g-3 ">
        <div className="col-sm-6 d-flex justify-content-center  ">
          <img
            className="object-fit-cover"
            src={prjctimg}
            alt="image"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div
          className="col-md-6 flex-wrap"
          style={{
            backgroundColor: "#F6FFE9",

            paddingLeft: "58px",
            paddingTop: "50px",
            backgroundImage: "url('/image/p-leaf.svg')", // Replace with your image path
            backgroundSize: "227px 213px",
            backgroundPosition: "right bottom",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right -35px bottom 5px",
            // backgroundSize: "contain",
          }}
        >
          <h5
            style={{
              font: "poppins",
              color: "#000000",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              // marginLeft:"200px"
            }}
          >
            Overview
          </h5>
          <p
            className="padding3"
            style={{
              font: "poppins",
              color: "#666666",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",

              paddingRight: "130px",
            }}
          >
            Our bold initiative aimed at generating carbon credits by mitigating
            and removing greenhouse gas emissions through strategic efforts to
            prevent planned and unplanned deforestation, engage in afforestation
            and reforestation, and safeguard peatland, we are not just reducing
            emission - we're cultivating a sustainable and harmonious future.
          </p>
          <p
            className="padding3"
            style={{
              font: "poppins",
              color: "#666666",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              paddingRight: "130px",
              paddingBottom: "55px",
            }}
          >
            <span
              style={{
                font: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Aim:
            </span>{" "}
            Aim: Partner with the CADTs for forest conservation, reforestation,
            rehabilitation, and agroforestry activities. The goal is to reduce
            greenhouse gas emissions.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="d-flex justify-content-evenly align-items-start flex-wrap ">
        <div className="d-flex flex-column  ">
          <img
            className="object-fit-cover "
            src={i1}
            alt="image"
            style={{
              width: "171px",
              height: "171px",
              marginLeft: "82px",
              marginBottom: "10px",
            }}
          />
          <h3
            style={{
              marginLeft: "85px",
              font: "Poppins",
              color: "#0C0C0C",
              fontWeight: "600",
              fontSize: "25px",
              lineHeight: "37px",
              marginBottom: "-20px",
              marginTop: "-10px",
            }}
          >
            Deforestation
          </h3>

          <p
            style={{
              font: "poppins",
              color: "#666666",
              fontWeight: "400",
              fontSize: "12px",
              width: "300px",
              lineHeight: "18px",
              padding: "10px",
              margin: "20px",
              boxSizing: "border-box",
              textAlign: "center",
            }}
          >
            Building Tomorrow, Investing Today: Empowering Sustainable Futures
            through Innovative Investments.
          </p>
        </div>
        <div
          style={{
            height: "189px",
            width: "1px",
            backgroundColor: "#85C71F",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />

        <div className="d-flex flex-column align-items-center mt-4">
          <img
            className="object-fit-cover "
            src={i2}
            alt="image"
            style={{
              width: "86px",
              height: "69px",
              marginTop: "25px",
              marginBottom: "25px",
              marginLeft: "-40px",
            }}
          />

          <h3
            style={{
              font: "poppins",
              color: "#0C0C0C",
              fontWeight: "600",
              fontSize: "25px",
              lineHeight: "37px",
              marginBottom: "-20px",
              marginTop: "28px",
            }}
          >
            Extinction
          </h3>

          <p
            style={{
              font: "poppins",
              color: "#666666",
              fontWeight: "400",
              fontSize: "12px",
              width: "300px",
              lineHeight: "18px",
              padding: "10px",
              margin: "20px",
              boxSizing: "border-box",
              textAlign: "center",
            }}
          >
            The Philippine Eagle is an IUCN Red List Critically Endangered
            species, with only 400 breeding pairs remaining on 4 islands.
          </p>

          <p
            style={{
              font: "poppins",
              color: "#666666",
              fontWeight: "400",
              fontSize: "12px",
              width: "300px",
              lineHeight: "18px",

              marginTop: "-30px",
              boxSizing: "border-box",
              textAlign: "center",
            }}
          >
            An IUCN Red List Critically Endangered (CR) species is one that has
            been categorized by the International Union for Conservation of
            Nature as facing an extremely high risk of extinction in the wild,
            the last stage before complete extinction.
          </p>
        </div>
        <div
          style={{
            height: "189px",
            width: "1px",
            backgroundColor: "#85C71F",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />

        <div className="d-flex flex-column align-items-end ">
          <img
            className="object-fit-cover "
            src={i3}
            alt="image"
            style={{
              width: "171px",
              height: "171px",
              justifyItems: "center",
              marginRight: "125px",
              // marginBottom: "20px",
            }}
          />
          <h3
            style={{
              marginRight: "125px",
              font: "poppins",
              color: "#0C0C0C",
              fontWeight: "600",
              fontSize: "25px",
              lineHeight: "37px",
              marginBottom: "-20px",
            }}
          >
            Exploitation
          </h3>

          <p
            style={{
              font: "poppins",
              color: "#666666",
              fontWeight: "400",
              fontSize: "12px",
              width: "300px",
              lineHeight: "18px",
              padding: "10px",
              margin: "20px",
              boxSizing: "border-box",
              textAlign: "center",
              marginRight: "50px",
            }}
          >
            Tribal leaders bear the responsibility for the economic prosperity
            of their communities, lacking access to financing or investments.
            This leaves leadership with no pragmatic choice but to entertain
            offers from extractive sectors to exploit timber and mineral
            resources{" "}
            <span
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "550",
                fontSize: "12px",
                lineHeight: "18px",
              }}
            >
              within their territories
            </span>
            .
          </p>
        </div>
      </div>
      <br />
      <br />
      <div>
        <div
          style={{
            position: "relative", // Set the container to relative positioning
            backgroundImage: "url(/image/prjctblr.png)", // Relative to the public folder
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "100vh",
            width: "100%",
            overflow: "hidden",
            padding: "95px 0px 95px 0px",
          }}
        >
          <img
            src="/image/prjctleaf3.svg" // Replace with the actual path to your top image
            alt="Top Image"
            style={{
              position: "absolute",
              top: "-170px", // Adjust as needed
              left: "60%",
              transform: "translateX(-50%)",
              width: "265px", // Adjust size as needed
              height: "auto",
              maxWidth: "100%",
            }}
          />
          <img
            src="/image/prjctleaf2.svg" // Replace with the actual path to your right corner image
            alt="Right Corner Image"
            style={{
              position: "absolute",
              bottom: "-60px", // Adjust as needed
              right: "10px",
              width: "265px", // Adjust size as needed
              height: "auto",
              maxWidth: "100%",
            }}
          />

          <div className="d-flex justify-content-center  ">
            <div className="d-flex flex-column align-items-center gap-4 p-4 ">
              <h1
                style={{
                  font: "poppins",
                  color: "#FFFFFF",
                  fontWeight: "600",
                  fontSize: "40px",
                  lineHeight: "60px",
                  justifyContent: "start",
                  width: "100%",
                }}
              >
                Goals For The Next 25-30 Years
              </h1>
              <div className="d-flex flex-row gap-4 flex-wrap flex-md-nowrap justify-content-center ">
                <span
                  className=" p-5"
                  style={{
                    textAlign: "center",
                    height: "259px",
                    width: "393px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div className="d-flex flex-row gap-3">
                    <img
                      className="object-fit-cover"
                      src={bpoint}
                      alt="image"
                      style={{
                        width: "32px",
                        height: "30px",
                      }}
                    />

                    <h3
                      style={{
                        font: "poppins",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "22px",
                        lineHeight: "33px",
                        textAlign: "start",
                      }}
                    >
                      Strategic Carbon Portfolio
                    </h3>
                  </div>

                  <p
                    style={{
                      font: "poppins",
                      color: "#666666",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "start",
                      paddingLeft: "49px",
                    }}
                  >
                    Protect over 500,000 hectares of tropical rainforest and the
                    species within
                  </p>
                </span>
                <span
                  class="  p-5"
                  style={{
                    height: "320px",
                    width: "380px",
                    textAlign: "center",
                    height: "259px",
                    width: "393px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div className="d-flex flex-row gap-3 ">
                    <img
                      className="object-fit-cover"
                      src={bpoint}
                      alt="image"
                      style={{
                        width: "32px",
                        height: "30px",
                      }}
                    />

                    <h3
                      style={{
                        font: "poppins",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "22px",
                        lineHeight: "33px",
                        textAlign: "start",
                      }}
                    >
                      Strategic Carbon Portfolio
                    </h3>
                  </div>
                  <p
                    style={{
                      font: "poppins",
                      color: "#666666",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "start",
                      paddingLeft: "49px",
                    }}
                  >
                    Replant and restore natural forest on over 150,000 hectares
                    of indigenous and ancestral lands
                  </p>
                </span>
                <span
                  class=" p-5"
                  style={{
                    height: "320px",
                    width: "380px",
                    textAlign: "center",
                    height: "259px",
                    width: "393px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div className="d-flex flex-row gap-3">
                    <img
                      className="object-fit-cover"
                      src={bpoint}
                      alt="image"
                      style={{
                        width: "32px",
                        height: "30px",
                      }}
                    />

                    <h3
                      style={{
                        font: "poppins",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "22px",
                        lineHeight: "33px",
                        textAlign: "start",
                      }}
                    >
                      Strategic Carbon Portfolio
                    </h3>
                  </div>
                  <p
                    style={{
                      font: "poppins",
                      color: "#666666",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "start",
                      paddingLeft: "49px",
                    }}
                  >
                    Introduce a sustainable socio-economic model to the region
                    based on preservation not destruction
                  </p>
                </span>
              </div>
              <div className="d-flex flex-row gap-4 flex-wrap flex-md-nowrap justify-content-center ">
                <span
                  class=" p-5"
                  style={{
                    height: "320px",
                    width: "380px",
                    textAlign: "center",
                    height: "259px",
                    width: "393px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div className="d-flex flex-row gap-3">
                    <img
                      className="object-fit-cover"
                      src={bpoint}
                      alt="image"
                      style={{
                        width: "32px",
                        height: "30px",
                      }}
                    />

                    <h3
                      style={{
                        font: "poppins",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "22px",
                        lineHeight: "33px",
                        textAlign: "start",
                      }}
                    >
                      Strategic Carbon Portfolio
                    </h3>
                  </div>
                  <p
                    style={{
                      font: "poppins",
                      color: "#666666",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "start",
                      paddingLeft: "49px",
                    }}
                  >
                    Protect the land, culture and heritage of recognized tribes
                    of that Region
                  </p>
                </span>
                <span
                  class=" p-5"
                  style={{
                    height: "320px",
                    width: "380px",
                    textAlign: "center",
                    height: "259px",
                    width: "393px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div className="d-flex flex-row gap-3">
                    <img
                      className="object-fit-cover"
                      src={bpoint}
                      alt="image"
                      style={{
                        width: "32px",
                        height: "30px",
                      }}
                    />

                    <h3
                      style={{
                        font: "poppins",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "22px",
                        lineHeight: "33px",
                        textAlign: "start",
                      }}
                    >
                      Strategic Carbon Portfolio
                    </h3>
                  </div>
                  <p
                    style={{
                      font: "poppins",
                      color: "#666666",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "start",
                      paddingLeft: "49px",
                    }}
                  >
                    Transform economic opportunities for over 250,000 indigenous
                    people who reside in the area
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="container">
        <div
          style={{ gap: "25px" }}
          className="d-flex flex-column flex-sm-row g-3 "
        >
          <div className="col-sm-6 d-flex justify-content-center  ">
            <img
              className="object-fit-cover image-height3  "
              src={prjctlast}
              alt="image"
            />
          </div>

          <div className="col-sm-6 ">
            <p
              style={{
                fontFamily: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "60px",
                top: "982px",
                left: "197px",
              }}
            >
              Approach
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              The project aims to partner with the CADTs for forest
              conservation, reforestation, rehabilitation, and agroforestry
              activities. The goal is to reduce greenhouse gas emissions.
            </p>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div
                class="accordion-item "
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                    style={{
                      border: "1px solid #ACACAC",
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                    }}
                  >
                    Financial Support
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      color: "#666666",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    class="accordion-body"
                  >
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Dedicated to development support with developer's
                          commitment to ensure funding suffices for initial
                          carbon credit issuance
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse2"
                    aria-expanded="false"
                    aria-controls="flush-collapse2"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Land Use And Activities
                  </button>
                </h2>
                <div
                  id="flush-collapse2"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          {" "}
                          Forest areas for reducing deforestation and
                          rehabilitating degraded forests
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Non-forest areas for reforestation and agroforestry
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse7"
                    aria-expanded="false"
                    aria-controls="flush-collapse7"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Participatory Process (FPIC)
                  </button>
                </h2>
                <div
                  id="flush-collapse7"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Participatory process to engage with indigenous people
                          to work with them on how they want to develop their
                          land (Social Impact Land Use Planning, SILUP)
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          CADTs decide land use for the project through
                          Participatory Land Use Planning (PLUP)
                        </span>
                      </div>

                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Official project boundaries determined by CADTs
                          through PLUP
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse6"
                    aria-expanded="false"
                    aria-controls="flush-collapse6"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Land Access
                  </button>
                </h2>
                <div
                  id="flush-collapse6"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          {" "}
                          CADT's access to their land will never be restricted
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5"
                    aria-expanded="false"
                    aria-controls="flush-collapse5"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Performance Evaluation
                  </button>
                </h2>
                <div
                  id="flush-collapse5"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Annual project evaluation to measure project's
                          performance
                        </span>
                      </div>
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Measurement of avoided deforestation and replanted
                          forest hectares
                        </span>
                      </div>

                      {/* <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Issuance of carbon credits by DENR based on carbon
                          impact
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse4"
                    aria-expanded="false"
                    aria-controls="flush-collapse4"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Benefit Sharing
                  </button>
                </h2>
                <div
                  id="flush-collapse4"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          Economic and social benefits explained through the
                          Benefit Sharing Mechanism
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div
                class="accordion-item"
                style={{
                  borderBottom: "none",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                }}
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse3"
                    aria-expanded="false"
                    aria-controls="flush-collapse3"
                    style={{
                      borderRadius: "10px",
                      fontFamily: "poppins",
                      color: "#85C71F",
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "28.5px",
                      border: "1px solid #ACACAC",
                    }}
                  >
                    Consent
                  </button>
                </h2>
                <div
                  id="flush-collapse3"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className="d-flex flex-column g-3">
                      <div className="d-flex flex-row g-3">
                        <div>
                          <img
                            className="object-fit-cover"
                            src={bpoint}
                            alt="image"
                            style={{
                              width: "14px",
                              height: "13px",
                              // borderRadius: "20px",
                            }}
                          />
                        </div>
                        <span className="ms-2">
                          CADT gives consent to participate in the project based
                          on the understanding of relevant information and prior
                          to the commencement of activities
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Projects;
