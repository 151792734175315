import { Fragment } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./home";
import About from "./about us";
import Solutions from "./solutions";
import Projects from "./project";
import Team from "./team";
import Contactus from "./contact";
import Layout from "./layout";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/solution" element={<Solutions />} />
      <Route path="/project" element={<Projects />} />
      <Route path="/team" element={<Team />} />
      <Route path="/contact" element={<Contactus />} />
    </Route>
  )
);
