import React from "react";
import slide2 from "../../assets/images/H&F/SlidePhoto2.jpg";
import slide1 from "../../assets/images/H&F/s1neww.jpg";
import slide3 from "../../assets/images/H&F/s3.jpg";
import cntctbtn from "../../assets/images/H&F/chevron-right (2).svg";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import "./Screen.css";

const Screen = () => {
  const Navigate = useNavigate();
  const handleButtonClick = () => {
    Navigate("/contact");
  };

  return (
    <div>
      <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={slide2} class="d-block w-100" alt="imag"  />
            <div class="h-100 carousel-caption d-md-block">
              <div className="h-100 d-flex justify-content-center flex-column align-items-center">
                <h2
                  className="heading1 mt-4 display-1 "
                  style={{
                    fontFamily: "poppins",
                    color: "#FFFFFF",
                    fontWeight: "700",
                  }}
                >
                  ECOVISION CAPITAL
                </h2>
                <h3
                  className=" heading2 "
                  style={{
                    fontFamily: "poppins",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  Empowering Futures, Transforming Lives
                </h3>
              </div>
            </div>
          </div>
          <div class="carousel-item ">
            <img src={slide1} class="d-block w-100" alt="imag"  />
            <div class="h-100 carousel-caption d-md-block">
              <div className="h-100 d-flex justify-content-center flex-column align-items-center">
                <h2
                  className="heading1 mt-4 display-1 "
                  style={{
                    fontFamily: "poppins",
                    color: "#FFFFFF",
                    fontWeight: "700",
                  }}
                >
                  ECOVISION CAPITAL
                </h2>
                <h3
                  className=" heading2 "
                  style={{
                    fontFamily: "poppins",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  Empowering Futures, Transforming Lives
                </h3>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src={slide3} class="d-block w-100" alt="imag"  />
            <div class="h-100 carousel-caption d-md-block">
              <div className="h-100 d-flex justify-content-center flex-column align-items-center">
                <h2
                  className="heading1 mt-4 display-1 "
                  style={{
                    fontFamily: "poppins",
                    color: "#FFFFFF",
                    fontWeight: "700",
                  }}
                >
                  ECOVISION CAPITAL
                </h2>
                <h3
                  className=" heading2 "
                  style={{
                    fontFamily: "poppins",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  Empowering Futures, Transforming Lives
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen;
const Button = styled.div`
  display: flex;
  width: 163px;
  height: 47px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 10px;
  /* border: 1px solid #000; */
  background-color: #85c71f;
  border-radius: 30px;
`;
