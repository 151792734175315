import React, { useState, useEffect } from "react";
import logo from "../../assets/images/H&F/logo-og.svg";
import styled from "styled-components";
import cntctbtn from "../../assets/images/H&F/chevron-right (2).svg";
import { Link, NavLink } from "react-router-dom";
import "./style.css";

const links = [
  { label: "Home", path: "/" },
  { label: "About Us", path: "/about" },
  { label: "Solutions", path: "/solution" },
  { label: "Projects", path: "/project" },
  { label: "Team", path: "/team" },
];

const Header = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    if (
      isSidebarOpen &&
      !event.target.closest(".sidebar") &&
      !event.target.closest(".hamburger-button")
    ) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <>
      <NavBar className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="navbar-container">
          <div className="d-flex align-items-center">
            <HamburgerButton className="hamburger-button" onClick={toggleSidebar}>
              &#9776;
            </HamburgerButton>
            <Link className="navbar-brand" to="/">
              <LogoImage src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="d-none d-lg-flex flex-row gap-5 navbar-nav align-items-center flex-wrap">
            {links.map((link) => (
              <NavLink
                key={link.path}
                className={({ isActive }) =>
                  `text-decoration-none ${
                    isActive ? "activeLink" : "inactiveLink"
                  }`
                }
                to={link.path}
              >
                {link.label}
              </NavLink>
            ))}
            <Link to={"/contact"} className="text-decoration-none text-white">
              <Button>
                Contact Us
                <img src={cntctbtn} alt="icon" />
              </Button>
            </Link>
          </div>
        </div>
      </NavBar>
      <Sidebar className="sidebar" isOpen={isSidebarOpen}>
        <SidebarContent>
          <SidebarLogo src={logo} alt="Logo" style={{width:"75%",}} /> {/* New: Logo in Sidebar */}
          {links.map((link) => (
            <NavLink
              key={link.path}
              className="text-decoration-none"
              to={link.path}
              onClick={toggleSidebar}
            >
              {link.label}
            </NavLink>
          ))}
          <Link to={"/contact"} className="text-decoration-none text-white" onClick={toggleSidebar}>
            <Button>
              Contact Us
              <img src={cntctbtn} alt="icon" />
            </Button>
          </Link>
        </SidebarContent>
      </Sidebar>
    </>
  );
};

export default Header;

const NavBar = styled.nav`
  width: 100%;
  .navbar-container {
    max-width: 1620px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  @media (max-width: 576px) {
    .navbar-container {
      padding: 0 10px;
    }
  }
`;

const Button = styled.div`
  color: #ffffff;
  display: flex;
  width: 163px;
  height: 47px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 10px;
  background-color: #85c71f;
  border-radius: 30px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
`;

const HamburgerButton = styled.button`
  background: none;
  color: #49710D;
  border: none;
  font-size: 30px;  
  cursor: pointer;
  display: block;
  margin-left:10;
  /* margin-right: px;  */
  /* Adjust the space between the button and the logo */
  @media (min-width: 992px) {
    display: none;
  }
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-250px")};
  width: 250px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease-in-out;
  z-index: 1000;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  a {
    font-size: 18px;
    color: #333;
    text-decoration: none;
    &:hover {
      color: #85c71f;
    }
  }
`;

const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 576px) {
    width: 290px; /* Adjust the width for small screens */
  }
`;

const SidebarLogo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
`;
