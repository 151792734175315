import React from "react";
import ableft from "../../assets/images/about/Rectangle 549.png";
import abright from "../../assets/images/about/Rectangle 550.png";
import Rightimage from "../../assets/images/home/Rectangle 532.png";
import Screen1 from "./Screen1";

const About = () => {
  return (
    <div>
      <Screen1 />
      <br />
      <br />
      <br />
      <div className="container">
        <div
          style={{ gap: "12px" }}
          className="d-flex flex-column-reverse flex-md-row"
        >
          <div className="col-sm-6  ">
            <p
              style={{
                fontFamily: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "60px",
                top: "982px",
                left: "197px",
              }}
            >
              Ecovision Capital Pte Ltd
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              Our unwavering commitment to this noble vision is encapsulated in
              our five foundational pillars: EcoCarbon, EconTech, EcoEnergy,
              EcoInnovation, and EcoWellness.
            </p>

            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              At EcoVision, we meticulously select distinguished experts in each
              pillar and cultivate strategic partnerships to develop distinctive
              initiatives that drive tangible impact. Presently, our focus
              shines on the EcoCarbon pillar, where we have taken a momentous
              stride by fully backing a visionary carbon developer to spearhead
              a large-scale, premium carbon credit program. Aligned with our
              ambitious objectives, we aim to secure USD 100 million to amplify
              our collaboration's impact across all pillars.
            </p>

            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              This substantial funding will bolster the success of our solutions
              as we collaborate with domain experts. Our ultimate aim is to
              catalyze a positive ripple effect, contributing to a sustainable,
              equitable, and flourishing world.
            </p>
            <p
              style={{
                fontFamily: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
                // maxWidth:"400px"
              }}
            >
              Embark on this transformative journey with us as we endeavor to
              raise funds and contribute to a legacy that transcends
              generations. At EcoVision Capital, innovation converges with
              responsibility, and every investment represents a significant
              stride towards a brighter, more sustainable future.
            </p>
          </div>

          <div className="col-md-6 d-flex justify-content-center ">
            <img
              className="object-fit-cover"
              src={Rightimage}
              alt="image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <div
        style={{
          backgroundImage: "url(/image/Rectangle_551.png)", // Relative to the public folder
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "50px 30px 80px 30px",
          width: "100%",
          // height: '400px'
        }}
      >
        <div class="d-flex justify-content-evenly flex-wrap">
          <div className="d-flex flex-column align-items-start">
            <h3
              style={{
                marginLeft: "100px",
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "37px",
                marginBottom: "-20px",
              }}
            >
              Our Vision
            </h3>

            <p
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "12px",
                width: "300px",
                lineHeight: "18px",
                padding: "10px",
                margin: "20px",
                boxSizing: "border-box",
                textAlign: "center",
              }}
            >
              Building Tomorrow, Investing Today: Empowering Sustainable Futures
              through Innovative Investments
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <h3
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "37px",
                marginBottom: "-20px",
              }}
            >
              Our Culture
            </h3>

            <p
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "12px",
                width: "300px",
                lineHeight: "18px",
                padding: "10px",
                margin: "20px",
                boxSizing: "border-box",
                textAlign: "center",
                // marginBlock: "0",
              }}
            >
              Through partnership, we participate, we provide, we prevail, we
              prosper
            </p>
          </div>

          <div className="d-flex flex-column align-items-end">
            <h3
              style={{
                marginRight: "110px",
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "37px",
                marginBottom: "-20px",
              }}
            >
              Our Belief
            </h3>

            <p
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "12px",
                width: "300px",
                lineHeight: "18px",
                padding: "10px",
                margin: "20px",
                boxSizing: "border-box",
                textAlign: "center",
                // marginBlock: "0",
              }}
            >
              We strongly believe that fostering peace and cultivating
              harmonious relationships form a mutually beneficial path to
              prosperity
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

      <div className="container">
        <div style={{ gap: "25px" }} className="d-flex flex-column flex-sm-row">
          <div className="col-md-6 d-flex justify-content-center ">
            <img
              className="object-fit-cover"
              src={ableft}
              alt="image"
              style={{
                width: "90%",
                height: "100%",
              }}
            />
          </div>

          <div className="col-sm-6 ">
            <p
              style={{
                fontFamily: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "60px",
              }}
            >
              Our Company
            </p>

            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              At EcoVision, we select esteemed domain experts within each pillar
              and form strategic partnerships to craft unique propositions that
              drive meaningful impact.
            </p>

            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              Currently, our spotlight is on the EcoCarbon pillar, where we have
              taken a significant step by fully funding a visionary carbon
              developer to lead a large-scale, premium carbon credit program. In
              line with our ambitious goals, we aspire to raise USD100 million,
              enhancing the impact of our collaboration across all pillars.
            </p>

            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              This substantial funding will fortify the success of our solutions
              as we partner with domain experts. Our overarching goal is to
              create a positive ripple effect, contributing to a sustainable,
              equitable, and thriving world.
            </p>

            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              Join us on our journey as we strive to raise funds and contribute
              to a legacy that transcends generations. At EcoVision Capital,
              innovation meets responsibility, and every investment is a
              significant step towards a better, more sustainable future.
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <div className="container">
        <div
          style={{ gap: "12px" }}
          className="d-flex flex-column-reverse flex-sm-row"
        >
          <div className="col-sm-6 ">
            <p
              style={{
                fontFamily: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "60px",
                top: "982px",
                left: "197px",
              }}
            >
              Our Reward
            </p>
            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              We do not just obtain good returns on investment, but at the same
              time make a resounding impact on the environment.
            </p>

            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              Our projects are not satisfied just with the status quo of chasing
              good ROI; instead, it's a visionary endeavor, a commitment to
              forging a sustainable future that resonates through the ages.
            </p>

            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                left: "199px",
              }}
            >
              We take a bold step in investing in a future where the joy of
              impact surpasses financial portfolios, leaving an everlasting
              imprint on a world we actively contribute to healing. Our
              investment isn't just a transaction; it's a transformative journey
              towards a brighter, more sustainable tomorrow.
            </p>
          </div>

          <div className="col-sm-6 d-flex justify-content-center ">
            <img
              className="object-fit-cover"
              src={abright}
              alt="image"
              style={{
                width: "90%",
                height: "100%",
              }}
            />
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default About;
