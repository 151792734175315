import React from "react";
import slide3 from "../../assets/images/project/Rectangle 530.png";

const Screen3 = () => {
  return (
    <div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src={slide3} class="d-block w-100" alt="image" />
          <div class="h-100 carousel-caption d-md-block">
            <div className="h-100 d-flex justify-content-center flex-column align-items-center">
              <h2
                className="mt-4 display-1"
                style={{
                  fontFamily: "poppins",
                  color: "#FFFFFF",
                  fontWeight: "700",
                }}
              >
                Our Project
              </h2>
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                Project Peace
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen3;
