import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { router } from "./Pages/router";

function App() {
  return <RouterProvider router={router} />;
}

export default App;
