import React, { useState } from "react";
import styled from "styled-components";
import cntctbtn from "../../assets/images/H&F/chevron-right (2).svg";
import icon2 from "../../assets/images/contact/icon2.svg";
import icon3 from "../../assets/images/contact/icon3.svg";
import successIcon from "../../assets/images/contact/success.png";
import "./Contactus.css";
import Screen5 from "./Screen5";
import Modal from "react-bootstrap/Modal";

const Contactus = () => {
  const initialFormData = {
    firstname: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const { firstname, surname, email, phone, message } = formData;

    if (firstname && surname && email && phone && message) {
      const formData = new FormData(event.target);

      formData.append("access_key", "56d741f4-4d1e-4c1a-bae5-d52f96921d96");

      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        event.target.reset();
        setFormData(initialFormData); // Reset form data
        setSubmitted(true); // Set submitted state to true
        handleShow(); // Show modal
      } else {
        handleShow(); // Show modal with error message
      }
    }
  };

  return (
    <div>
      <Screen5 />
      <br />
      <br />
      <br />

      <div className="container">
        <div
          style={{ gap: "60px 20px" }}
          className="d-flex flex-column flex-sm-row "
        >
          <div className="col-sm-6 ">
            <h6
              style={{
                font: "poppins",
                color: "#000000",
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "37px",
              }}
            >
              Contact Information
            </h6>

            <p
              style={{
                font: "poppins",
                color: "#666666",
                fontWeight: "400",
                fontSize: "15px",
                lineHeight: "23px",
                maxWidth: "352px",
              }}
            >
              Contact us or simply fill in the form and we will get back to you
              as soon as possible.
            </p>

            <div className="d-flex flex-row g-3">
              <div>
                <img
                  className="object-fit-cover"
                  src={icon2}
                  alt="image"
                  style={{
                    width: "31px",
                    height: "31px",
                  }}
                />
              </div>
              <span
                className="ms-2"
                style={{
                  font: "poppins",
                  color: "#666666",
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "23px",
                  maxWidth: "352px",
                }}
              >
                admin@ecocaraga.com
              </span>
            </div>
            <br/>

            <div className="d-flex flex-row g-3">
              <div>
                <img
                  className="object-fit-cover"
                  src={icon3}
                  alt="image"
                  style={{
                    width: "31px",
                    height: "31px",
                  }}
                />
              </div>
              <span
                className="ms-2"
                style={{
                  font: "poppins",
                  color: "#666666",
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "23px",
                  maxWidth: "352px",
                }}
              >
                11 Collyer Quay, #05-05 The Arcade, Singapore 049317
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <form className="d-flex flex-column gap-4" onSubmit={onSubmit}>
              <div className="d-flex gap-5">
                <div className="form-group col-md-6">
                  <label htmlFor="firstName">First Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstname"
                    id="firstName"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="lastName">Surname*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="surname"
                    id="lastName"
                    value={formData.surname}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="d-flex gap-5">
                <div className="form-group col-md-6">
                  <label htmlFor="email">E-mail*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="phone">Phone*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="message">Message*</label>
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  rows="4"
                  style={{ width: "100%" }}
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>

              <SubmitButton className="header" type="submit">
                Submit{" "}
                <img src={cntctbtn} alt="icon" style={{ marginLeft: "2px" }} />
              </SubmitButton>
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div style={{ marginBottom: "-55px" }}>
        <iframe
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.817288114922!2d103.84985507472457!3d1.2834949987043052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190ec7a45f71%3A0x3c0342268c636b68!2sThe%20Arcade!5e0!3m2!1sen!2sin!4v1719393170474!5m2!1sen!2sin"
          style={{ width: "100%", height: "450px" }}
        ></iframe>
      </div>

      <Modal  show={showModal} onHide={handleClose} centered>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            src={successIcon}
            alt="Success"
            style={{
              width: "50px",
              height: "50px",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          />
          <Modal.Body
            style={{
              textAlign: "center",
              color: "#85c71f",
              fontSize: "21px",
              fontWeight: "540",
              marginBottom: "10px",
            }}
          >
            Form Submitted Successfully
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Contactus;

const Button = styled.button`
  display: flex;
  width: 137px;
  height: 53px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 10px;
  background-color: ${(props) => (props.submitted ? "#6da01c" : "#85c71f")};
  border-radius: 30px;
  border: none;
  color: #ffffff; /* Text color set to white */
`;

const SubmitButton = styled(Button)`
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #77b300;
  }
`;
