import React from "react";
import p8 from "../../assets/images/team/Bernard Ho PNG - copy.png";
import p3 from "../../assets/images/team/Jana Kho PNG - copy.png";
import p5 from "../../assets/images/team/Dr. Lim Boh Soon PNG - copy.png";
import p6 from "../../assets/images/team/Richard Loi PNG - copy.png";
import p7 from "../../assets/images/team/Sophia Ong PNG - copy.png";
import p4 from "../../assets/images/team/Richard Pattle PNG - copy.png";
import p1 from "../../assets/images/team/Sofia Khoo PNG - copy.png";
import p2 from "../../assets/images/team/Thomas Kho PNG - copy.png";
import "./Padding.css";
import Screen4 from "./Screen4";

const Team = () => {
  return (
    <div>
      <Screen4 />
      <br />
      <br />
      <br />
      <div
        style={{ paddingLeft: "100px", paddingRight: "100px" }}
        class="row g-4 padding"
      >
        <div class="col-md-3">
          <div class="card h-10 custom-card">
            <img
              src={p1}
              class="card-img-top"
              alt="img"
              style={{ height: "375px" }}
            />
            <div style={{ height: "470px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Sofia Khoo
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Chairman
              </h6>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Founding Partner
              </h6>
              
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                As Director of the family business, Sofia led strategic efforts
                for sustainable growth and efficiency. Managed wood exploitation
                and plywood manufacturing while ensuring regulatory compliance.
                Oversaw financial assets, investments, and risk mitigation.
                Collaborated with stakeholders to optimize performance and
                fostered a culture of innovation and excellence within the team.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card h-100">
            <img
              src={p2}
              class="card-img-top"
              alt="img"
              style={{ height: "375px" }}
            />
            <div style={{ height: "470px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Thomas Kho
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                President
              </h6>
              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
               Founding Partner
              </h6>
              
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                As President of the family business, Thomas is a
                results-oriented professional who drives successful ventures and
                strategic investments, particularly in the forestry industry.
                Instrumental in decision-making across various ventures, he is
                positioned as a key leader in Teochew Poit Ip Huay Kuan,
                directing strategic initiatives and decision-making processes
                for impactful outcomes.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card h-100">
            <img
              src={p3}
              class="card-img-top"
              alt="img"
              style={{ height: "375px" }}
            />
            <div style={{ height: "470px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Jana Kho
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Vice President
              </h6>
              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                 Founding Partner
              </h6>
              
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                As a Venture Capital and Private Equity consultant, Jana adeptly
                led negotiations and formulated financing strategies in
                Guangdong, Fujian, and Shandong, navigating the dynamic
                landscape of the People's Republic of China. In family business
                and office leadership roles, she orchestrated asset and
                financial management across diverse sectors, including private
                equity, stocks, bonds, and hedge funds.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card h-100">
            <img
              src={p4}
              class="card-img-top"
              alt="img"
              style={{ height: "375px" }}
            />
            <div style={{ height: "470px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Richard Pattle
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Senior Strategic Advisor
              </h6>
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                Richard was commissioned into the Royal Air Force as a pilot
                specialising in combat helicopter duties during his 18-year
                career. He worked for the British Royal Family in two separate
                roles. In 2004, he became Equerry to Their Majesties King
                Charles III and Queen Camilla for two years, returning in 2008
                to be Their Majesties’ Master of the Household, a COO role.
                Richard was appointed Lieutenant of the Royal Victorian Order
                for Services to The Royal Family in 2014. He became Vice
                Chairman of Standard Chartered Private Bank that year, covering
                Ultra High Net Worth families globally. He assumed the role of
                CEO KEF Holdings upon leaving Standard Chartered in 2018, until
                the successful exit to a SoftBank portfolio entity. Richard
                became senior advisor to Zerodha, the Bangalore-based fintech
                unicorn in 2019 and is the cofounder of the Indian hedge fund
                True Beacon, and was CEO from inception in 2019 until 2024.
                Richard is Senior Advisor to EcoVision, providing international
                support to the Board from Dubai.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        style={{ paddingLeft: "100px", paddingRight: "100px" }}
        class="row g-4 padding"
      >
        <div class="col-md-3">
          <div class="card h-100">
            <img
              src={p5}
              class="card-img-top"
              alt="img"
              style={{ height: "375px" }}
            />
            <div style={{ height: "427px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Dr. Lim Boh Soon
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Senior Advisor
              </h6>
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                Dr. Lim, a seasoned professional in the venture capital and
                private equity industry with over 28 years of financial
                experience in Asia, serves as an Independent Director on the
                Boards of several publicly listed companies in Singapore,
                Malaysia, and the US, contributed his expertise to renowned
                organizations such as UBS Capital Asia Pacific Limited, The
                NatSteel Group, Rothschild Ventures Asia Limited, and The
                Singapore Technologies Group. He made history as the first
                non-Muslim CEO when establishing Kuwait Finance House in
                Singapore. Prior to this, Dr. Lim served as the inaugural
                foreign CEO of Vietcom bank Fund Management Company, overseeing
                the setup of the company and its fund management operations
                while successfully raising two private equity funds for
                Vietnam's largest State-owned Bank.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card h-100">
            <img
              src={p6}
              class="card-img-top"
              alt="img"
              style={{height: "375px" }}
            />
            <div style={{ height: "427px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Richard Loi
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Group CEO
              </h6>
              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Founding Partner
              </h6>
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                Richard Loi is a Senior Partner at Deloitte Singapore with over
                30 years of public accounting experience. He has previously led
                Deloitte Private in Singapore and Southeast Asia, serving
                high-net-worth individuals, family businesses, private
                enterprises, family offices, private equity, and fast-growing
                companies. In his advisory role, Richard focuses on business
                growth, wealth preservation, and safeguarding legacies. He
                collaborates with families on vision articulation, governance
                structure, and succession planning, and helps create family
                constitutions and agreements for future needs. Additionally,
                Richard provides services to listed companies, multinationals,
                and local private firms, guiding them through IPOs on the
                Singapore and Hong Kong Exchanges, and leading special
                investigations and system audits.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card h-100">
            <img
              src={p7}
              class="card-img-top"
              alt="img"
              style={{height: "375px" }}
            />
            <div style={{ height: "427px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Sophia Ong
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Group CFO
              </h6>
              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Founding Partner
              </h6>
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                Sophia, with over two decades of seasoned expertise in wealth
                management, successfully navigated leadership roles across
                diverse financial institutions. Her journey includes steering a
                proficient team of Relationship Managers and diligently
                overseeing portfolios for high-net-worth individuals and family
                offices. Proficient in portfolio performance analysis, she
                adeptly managed billion-dollar assets, demonstrating excellence
                in overseeing High Net Worth (HNW) clients. Her board experience
                highlights a collaborative approach, involving work with
                investment banks on Mergers and Acquisitions (M&A), IPO, PIPE,
                and restructuring initiatives.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card h-100">
            <img
              src={p8}
              class="card-img-top"
              alt="img"
              style={{ height: "375px" }}
            />
            <div style={{ height: "427px" }} class="card-body text-center ">
              <h5
                style={{
                  fontFamily: "poppins",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "30px",
                }}
                class="card-title"
              >
                Bernard Ho
              </h5>

              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "#66A700",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Group COO
              </h6>
              <p
                style={{
                  fontFamily: "poppins",
                  color: "#666666",
                  fontWeight: "300",
                  fontSize: "11px",
                  lineHeight: "16px",
                }}
                class="card-text"
              >
                Bernard, with over 30 years of experience in the Palm Oil
                plantation and Wood Industry in Indonesia, worked as the Head of
                GAPKI (Gabungan Pengusaha Kelapa Sawit) in West Kalimantan,
                Indonesia from 2008-2012. His latest professional experience
                involved handling Reforestation (HTI) as General Manager in
                Sungai Budi Group. Bernard also has experience in liaising with
                various government deputies, advising social and land
                overlapping issues, company's permit and legal issues. Bernard
                has worked in various roles with a number of large corporations
                such as Sinar Mas Group, Goodhope Asia Holdings LTD and Hutrinto
                Hutan Raya Group.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Team;
